
/* ======================================= Menú Pantalla Completa ======================================= */
nav {
  align-items: center;
  font-weight: 520;
  width: 100%;
}
.header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0px;
  background-color: #ffffff;
  z-index: 1000;

}
.navbar {
  margin-top: 0px;
  margin-left: 0px;
  background-color: #000000;
  display: flex;
  justify-content: right;
  justify-items: center;
  height: 60px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.247);
}
/* ============================================ Colores Menú ============================================ */
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
/* ============================================ Logo Tamaño ============================================ */
.navbar .navbar-logo img {
  position: absolute;
  left: 3%;
  top: 0;
  width: 80px;
  height: 60px;
}

nav > a {
  color: #000000;
  margin-right: 10px;
  font-weight: bold;

}

/* ================================== Color Barra Menú y fondo banner ================================== */

.footer-logo {
  visibility: hidden;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: right;
  margin-right: 2rem;
  margin-top: 10px;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

/* ========================================= Tamaño Letra Menu ========================================= */
.nav-item p {
  font-weight: bold;
  color: rgb(255, 255, 255);
  font-size: 14px;
  padding: 24px 10px;
}

li.nav-item.contacto p {
  background-color: var(--color-primary);
  color: #000000;

}

.nav-item:hover p {
  background-color: var(--color-primary);
  color: #000000;
}

/* ========================================== Color Letra Menu ========================================== */
.nav-links {
  padding: 0px 10px 0px 0px;
  background-color: #00000000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.footer-logo {
  position: fixed;
  background-color: #ffffff00;
  bottom: 0;
  width: 100%;
}
.footer-logo img {
  width: 100px;
  height: 30px;
  top: 200px;
}

/* =========================================== Responsive 960 =========================================== */
@media (max-width: 960px) {



  .nav-item p {
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 18px;
    padding: 20px;
  }
  
  .private {
    visibility: hidden;
  }

  .logo-home img {
    width: 20%;
    height: 10%;
    justify-content: center;
    margin-top: 0%;
  }

  .content-foo.black {
    margin-top: 5%;
  }

  .footer-logo {
    visibility: visible;
  }

  .navbar {
    background-color: #ffffffec;
    position: fixed;
    height: 80px;
    top: 0;
    margin-left: 0%;
    width: 100%;
  }

  li.nav-item {
    width: 100%;
    margin-top: 20px;
    padding: 20px;
  }
  li.nav-item.contacto p {
    background-color: #f8d80a00;
  }

  li.nav-item.contacto p {
    padding: 20px;
  }

/* ============================================= Menu Movil ============================================= */
  .nav-menu {
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 90px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #f2e205;
    z-index: 1;
  }
  .nav-menu.active {
    position: fixed;
    background-color: #fffffff1;
    width: 100%;
    height: 100%;
    margin-top: -10px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
/* ============================================= Texto Menu ============================================= */
/* ========================================= Icono Hamburguesa ========================================= */
  .menu-icon {
    position: relative;
    display: block;

    font-size: 1.8rem;
    cursor: pointer;
    -webkit-transform: translate(-100%, 50%);
    -moz-transform: translate(-100%, 50%);
    -ms-transform: translate(-100%, 50%);
    -o-transform: translate(-100%, 50%);
    transform: translate(-100%, 50%);
  }
/* ============================================ Color Icono ============================================ */
  .bi-list {
    position: absolute;
    color: #ffc52f;
    font-size: 2rem;
    margin: 0 auto;
    padding: 0 auto;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    width: 100%;
    background-color: #f2e205;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    z-index: 1;
  }

  button {
    display: none;
  }

  .footer-logo {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
    width: 100%;
  }
  .footer-logo img {
    background-color: #ffffff;
    width: 100px;
    height: 30px;
    top: 200px;
  }

  .footer-logo2 {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
    width: 100%;
  }

  .private h2 {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
    width: 100%;
  }
}
