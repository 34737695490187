.portafolio {
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: 60px;
  background-color: var(--color-primary);
  background-color: rgba(255, 255, 255, 0.363);
}

.producto-1 {
  width: 50%;
  display: flex;
  justify-content: center;
  float: left;
  height: 400px;
}

.producto-2 {
  width: 50%;
  display: flex;
  justify-content: center;
  float: left;
  height: 400px;
  text-align: justify;
  padding: 20px;
}
.producto-2 p {
  width: 100%;
  font-size: 18px;
  text-align: justify;
}

.producto-1-texto {
  width: 50%;
  height: 400px;
  display: flex;
  justify-content: center;
  float: right;
  background-color: rgb(255, 255, 255);
  padding: 20px;
}

.boton-info button {
  width: 100%;
  background-color: black;
  display: flex;
}

.producto-1-texto p {
  width: 100%;
  font-size: 18px;
  text-align: justify;
}
.example {
  width: 100%;
  display: flex;
  padding: 20px 100px;
}

p.description {
  width: 100%;
  padding: 30px;
  float: inline-end;
}

.card-body.producto:hover {
  background-color: black;
  -webkit-transform: rotateX(150deg); /* Safari */
  transform: rotateX(150deg);
}

.card {
  width: 12rem;
  cursor: pointer;
  width: 90%;
}

div.card.sol{
  background-color: rgb(158, 158, 158);
}

.content {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background: rgb(117, 19, 254);
  background: -moz-linear-gradient(
    90deg,
    rgba(117, 19, 254, 1) 0%,
    rgba(42, 125, 212, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(117, 19, 254, 1) 0%,
    rgba(42, 125, 212, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(117, 19, 254, 1) 0%,
    rgba(42, 125, 212, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7513fe",endColorstr="#2a7dd4",GradientType=1);
  padding: 5em;
  transform-style: preserve-3d;
  height: 400px;

  color: aliceblue;
}

.card:hover .content {
  transform: rotateY(0.5turn);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 400px;
}

.logo img {
  width: 400px;
}

.title {
  transform: translateZ(5rem);
  font-size: 2rem;
}

.subtitle {
  transform: translateZ(2rem);
}

.back {
  transform: rotateY(0.5turn);
  background-color: var(--color-primary);
}

.description {
  transform: translateZ(3rem);
}

.card2 {
  width: 100%;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  border-radius: 20px;
}

.card2 > * {
  margin: 0;
}

.card2title {
  color: #333;
}

.card2content {
  font-size: 18px;
  line-height: 18px;
  color: #333;
}

.card2form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card2form input {
  margin-top: 10px;
  outline: 0;
  background: rgb(255, 255, 255);
  box-shadow: transparent 0px 0px 0px 1px inset;
  padding: 0.6em;
  border-radius: 14px;
  border: 1px solid #333;
  color: black;
}

.card2form button {
  border: 0;
  color: rgb(0, 0, 0);
  padding: 0.68em;
  border-radius: 14px;
  font-weight: bold;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.sign-up:hover {
  opacity: 0.8;
}


/* =========================================== Responsive 960 =========================================== */


@media (max-width: 960px) {
  .producto-1 {
    width: 100%;
    position: relative;
    height: 200px;
  }

  .producto-2 {
    width: 100%;
    position: relative;
    height: 200px;
  }

  .producto-1-texto {
    width: 100%;
    position: relative;
    height: 200px;
  }

  .example {
    width: 100%;
    padding: 0px;
    display: flex;
  }

  p.description {
    width: 100%;
    padding: 0%;
  }
  .logo img {
    width: 100px;
  }
}
