.slider {
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: 60px;
}

.slider-box {
  position: relative;
  margin-top: 0px;
}

.slider h3 {
  background-color: rgba(0, 0, 0, 0.438);
  backdrop-filter: blur(5px);
  color: aliceblue;
}

.slider p {
  background-color: rgba(0, 0, 0, 0.438);
  width: 100%;
  padding: 10px;
  backdrop-filter: blur(5px);
  color: aliceblue;
}

.btn-slider {
  position: absolute;
  align-items: flex-end;
  margin-left: 110%;
  margin-top: -100px;

  width: 50px;
  height: 50px;
}

.hola a {
  width: 100px;
  height: 100px;
  display: flex;
  justify-items: center;
  justify-content: center;
}

.btn-slider {
  background-color: rgb(255, 94, 0);
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
}


.checar{
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: end;
  padding: 20px;
}


a.btn-formuario {
  background-color: rgb(0, 0, 0);
}










/* =========================================== Responsive 960 =========================================== */

@media (max-width: 960px) {
  .hola a {
    padding: 20px;
  }

.slider{
    margin-top: 80px;
    position: relative;
    align-items: left;
}
a.boton-formulario.btn-2 {
    font-size: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: orangered;
}



}




  
 
