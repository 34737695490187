.blog {
  position: relative;
  z-index: 0;
  margin-top: 80px;
  width: 80%;
  background-color: var(--color-primary);
  background-color: aliceblue;
  margin-left: 10%;
}

.comentarios {
  padding: 30px;
  position: absolute;
  width: 100%;
  background-color: aliceblue;
  display: flex;
  justify-content: flex-end;
}

.code-react{
  background-color: black;
  width: 100%;
  color: aliceblue;
 
}

.code-react code{
  
  color: white;

}




code.identificador {
  color: rgb(172, 115, 224);
}
code.reservada {
  color: rgb(93, 216, 247);
}

code.atributo {
  color: rgb(255, 142, 50);
}

code.tipo {
  color: rgb(2, 133, 255);
}

code.funcion {
  color: rgb(231, 235, 34);
}









.titulo h2 {
  text-align: center;
  padding: 20px 0px;
  font-size: 24px;
  font-weight: 900;
  width: 100%;
  margin-left: -20px;
}

.requerimientos h2 {
  text-align: center;
  font-size: 24px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.inicio-tutorial h2 {
  text-align: center;
  font-size: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

div.box-info {
  position: relative;
}

.requerimientos {
  width: 49%;
  padding: 4%;
  margin: 0 auto;
  float: right;
}

.requerimientos span {
  width: calc(100% + 60px);
  display: flex;
  justify-content: center;
  font-size: 12px;
  text-align: justify;
  margin-left: -40px;
}

.lista ol {
  font-size: 16px;
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.code span {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.informacion {
  width: 49%;
  float: left;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 20px;
}

.imagen img {
  width: 100%;
  float: right;
  background-color: black;
}

.info p {
  font-size: 12px;
  text-align: center;
}

.info span {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

.code code {
  background-color: rgb(0, 0, 0);
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  color: white;
  font-size: 12px;
  width: 49%;
  position: absolute;
  padding: 0px 10px 0px;
  left: 50%;
}

.inicio-tutorial {
  width: 100%;
  height: 200px;
  padding: 30px;
  display: block;
  float: left;
}

.inicio-tutorial h2 {
  text-align: center;
  background-color: var(--color-primary);
}

.inicio-tutorial p {
  width: 100%;
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 300;
}

span.tecnologias {
  background-color: rgb(223, 223, 223);
  font-size: 24px;
  justify-content: center;
}

/* Tabulacion codigo */
P.tabulacion {
  padding: 0px 60px;
}

b.tabulacion-doble {
  padding: 0px 60px;
}
.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

b.code-color {
  color: rgb(255, 0, 255);
}

b.code-color-variable {
  color: rgb(7, 119, 13);
}

p.red {
  color: red;
}

p.blue {
  color: blue;
}

p.green {
  color: green;
}

.inicio-tutorial h3 {
  text-align: center;
  background-color: var(--color-primary);
}

.code-2 span {
  color: rgb(0, 0, 0);
  font-size: 16px;
  text-align: justify;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

p.code {
  background-color: rgb(0, 0, 0);
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  color: white;
  font-size: 12px;
  padding: 5px;

  width: 100%;
  text-align: center;
}

.code-imagen {
  width: 100%;
}

.code-left {
  width: 50%;
  float: left;
  padding: 20px;
}

.code-left img {
  width: 100%;
  height: 400px;
}

.code-left p {
  font-size: 14px;
}

.code-right p {
  font-size: 14px;
}

.code-right img {
  width: 100%;
  height: 400px;
}

.code-right {
  width: 50%;
  float: right;
  padding: 20px;
}

.space {
  width: 100%;
  padding: 20px;
  float: left;
}

.space p {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 960px) {
  .blog {
    position: relative;
    z-index: 0;
    margin-top: 80px;
    width: 100%;
    margin-left: 0px;
  }

  /* Tabulacion codigo */
  P.tabulacion {
    padding: 0px 0px;
  }

  b.tabulacion-doble {
    padding: 0px 0px;
  }

  .informacion {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 10px;
  }

  .requerimientos {
    width: 100%;
    padding: 20px;
  }

  .titulo h2 {
    margin-left: 0px;
  }

  .requerimientos span {
    width: calc(100%);
    display: flex;
    justify-content: center;
    font-size: 12px;
    text-align: justify;
    margin-left: 0px;
  }

  .code code {
    font-size: 12px;
    width: 100%;
    left: 0px;
  }

  .code-right,
  .code-left {
    width: 100%;
    padding: 20px;
    position: relative;
  }
}
