.footer {
  width: 100%;
}

.aviso-privacidad {
  background-color: aliceblue;
  width: 100%;
  text-align: center;
}

a {
  color: black;
  font-weight: 100;
}
.social{
  margin-top: 60px;
}
.social-media,
.social-media.na {
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
}

.footer p {
  font-size: 16px;
  font-weight: 500;
}

div.text-center.p-4 {
  width: 100%;

  background-color: var(--color-primary);
}


.social-media {
  background-color: var(--color-primary);
  width: 100%;
}

.social-media a {
  margin: 30px;
}


.footer a{
font-weight: bold;
}
/* =========================================== Responsive 960 =========================================== */

@media (max-width: 960px) {
  .social span {
    text-align: center;
    width: 100%;
    font-size: 16px;
  }

  div.text-center.p-4 {
    font-size: 16px;
  }

  .aviso-privacidad {
    font-size: 16px;
  }

  .social-media a {
    padding: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
}


a:hover{
  padding: 0px;
}