.soluciones {
  position: relative;
  width: 100%;
  z-index: 0;
  background-color: var(--color-primary);
  background-color: #ffffff00;
  margin-top: 160px;
}

.medcur {
  position: relative;
  width: 100%;
  height: 400px;
}

.responsive.medcur {
  width: 50%;
  float: left;
  height: 400px;
}

.responsive.medcur {
  text-align: center;
}

.responsive.medcur p {
  text-align: justify;
  padding: 20px;
}

.responsive.imagen {
  width: 50%;
}

.zoom:hover {
  width: 100%;
  left: 0px;
  top: 0xp;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: 3s;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
}




/* SOLUCIONES
 */
 .productos {
  width: 100%;
  margin-top: 80px;
}

.productos h1,
h2 {
  text-align: center;
}

.productos p {
  text-align: center;
}

.box-productos {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 20px;
}

.box-left {
  float: left;
  width: 450px;
  height: 500px;
  padding: 20px;
  margin: 20px;
}

.box-left img {
  width: 450px;
  height: 500px;
}

.box-right {
  float: right;
  width: 450px;
  height: 500px;
  padding: 20px;
  margin: 20px;
}
.box-right img {
  width: 450px;
  height: 500px;

  z-index: 1;
}

.text-on {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.041);
  width: 450px;
  height: 150px;
  margin-top: -150px;
  z-index: 100;
}

.text-on h3 {
  color: white;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
}

/* 
#BOTON STYLE */
.text-on button {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.btn-productos {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.btn-productos a {
  border-radius: 4px;
  background-color: var(--color-primary);
  border: none;
  color: #000000;
  text-align: center;
  font-size: 12px;
  padding: 6px;
  width: 130px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-productos:hover a {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.219);
}

.btn-productos a span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn-productos a span:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;

  transition: 0.5s;
}

.btn-productos a:hover span {
  padding-right: 15px;
}

.btn-productos a:hover span:after {
  opacity: 1;
  right: 0;
}




h3{

  font-size: 20px;
  font-weight: 600;
  color: #5c5b5b;
  padding: 10px;
  text-transform: uppercase;
  
}

.card-servicios.productos {
  height:400px;
  text-align: center;
  border: 10px solid #0203201f;
  border-radius: 0px;
  box-shadow: inset 5px 5px 10px #bbb, inset -5px -5px 10px #fff;
  transition: 0.5s;

  

}

.card-servicios.productos:hover {
 
  border: 10px solid var(--color-primary);
  border-radius: 0px;
  box-shadow: 4px 4px 8px #00000056;
  transition: 0.5s;

  

}

.beneficios{
  background-color: rgb(255, 255, 255);
}


.icon-servicios.productos {
padding: 0;
margin: 0;
 width:100%;
 height: 400px;
 color: rgb(0, 0, 0);
 font-size: 30px;
 background-color: rgba(219, 4, 4, 0);
 margin: 20px auto;
 padding: 0px;
 box-shadow: 8px 8px 10px #f1101000, -8px -8px 10px #00000000;
 border-radius:10px ;
 -webkit-border-radius:10px ;
 -moz-border-radius:10px ;
 -ms-border-radius:10px ;
 -o-border-radius:10px ;
}


.productos-pages{
  width: 100%;
  margin-top: 70px;
}


.productos-pages  h1{
 text-align: center;
 font-size: 24px;
}

div.info {
  width: 100%;

 }
div.info p{
 text-align: center;
 padding: 20px;
 font-size: 18px;
}


.lista{

 padding: 20px;
 box-shadow:4px 4px 8px rgba(0, 0, 0, 0.493) ;
}

.lista ol{
  width: 100%;
  padding: 20px 90px;
}
.lista li{
  font-size: 16px;
 color: #000000;
font-weight: 600;
  padding: 20px;
  text-align: justify;
}


/* =========================================== Responsive 960 =========================================== */

@media (max-width: 960px) {



  .container .box .title h1 {
    color: rgb(0, 0, 0);
    font-size: 28px;
    text-align: justify;
   
  }

  .productos-pages{
    width: 100%;
    margin-top: 100px;
  }

  
.lista ol{
  width: 100%;
  padding: 10px 30px;
}
.lista li{
  font-size: 16px;
 color: #000000;
font-weight: 600;
  padding: 20px;
  text-align: justify;
}
  
  .responsive.medcur {
    width: 100%;
    position: relative;
    height: 200px;
  }




.productos{
  width: 100%;
 position: relative;
}

  .box-productos {
    width: 100%;
   position: relative;
   left: 0px;
   padding: 0px;
    
  }
  
  .box-left {
    float: left;
    width: 100%;
    height: 500px;
    padding: 0px;
    margin: 0px;
  }
  
 
  .box-right {
    float: left;
    width: 100%;
    height: 500px;
    padding: 0px;
    margin: 0px;
  }
 
  .box-left img {
    width: 100%;
    height: 500px;
  
    z-index: 1;
  }
  
  .box-right img {
    width: 100%;
    height: 500px;
  
    z-index: 1;
  }
  



}
