.servicios {
  position: relative;
  width: 100%;
  z-index: 10;
  margin-top: 60px;
  background-color: var(--color-primary);
  background-color: rgba(255, 255, 255, 0.363);
}
.hola{
  position: relative;
  z-index: 1000;
}

.containerHome {
  width: 100%;
  height: 600px;
  margin-top: 80px;
  display: flex;
  justify-content: space-even;
}

.color-home {
  background-color: #000000;
}

article.article-nosotros {
  font-weight: 900;
}

.imgLetrasLogo {
  width: 440px;
}
.imgHome {
  width: 430px;
  margin-top: 15px;
}

.titulo-home {
  font-size: 70px;
  color: white;
}

.container-home-letras {
  margin: 100px 0px 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.boton-contactanos-home {
  padding: 10px;
  width: 40%;
  background-color: #ffc52f;
  border-radius: 10px;
  font-size: 25px;
  border-color: #606060;
}

.boton-contactanos-home:hover {
  padding: 10px;
  width: 40%;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 25px;
  border-color: #606060;
}

.link-buton {
  text-decoration: none !important;
  color: white;
}

/****** inicia el body ******/

/****** software a la medida ******/
.container-body-home {
  margin: 0px;
}

#titulo-software {
  font-size: 40px;
  text-align: center;
}
#titulo-necesidad {
  font-size: 30px;
  text-align: center;
  margin: 30px;
}

.contenedor-caracteristicas {
  display: flex;
  width: 100%;
}
.caracteristicas {
  margin-left: 10px;
}
.icon-caracteristicas {
  text-align: center;
}
h5 {
  font-size: 20px;
  margin: 25px 50px 50px;
}

/****** inicia beneficios de trabajar con nosotros ******/
.contenedor-descubre-beneficios {
  display: flex;
}

img {
  width: 80%;
}

.contenedor-iconos-info {
  display: flex;
}
.informacion-beneficios {
  margin-top: 80px;
}

.titulo-info-beneficios {
  font-weight: bolder;
}
.subtitulo-info-beneficios {
  text-align: justify;
  margin: 30px;
}

.contenedor-informacion-beneficios-caracteristicas {
  margin-left: 40px;
}

.informacion-beneficios-caracteristicas {
  margin-bottom: 65px;
  margin-top: 11px;
}

.icono-beneficios {
  margin-bottom: 30px;
  background-color: #edf0f4;
  border-radius: 50%;
  width: 100%;
  text-align: center;
  padding: 8px;
  border: 3px solid rgb(77, 88, 99);
  color: #000000;
}

#informacion {
  text-align: justify;
}

.info-clientes {
  display: flex;
}

.contenedor-info-clientes {
  display: flex;
  align-items: flex-start;
}
.subtitulo-info-clientes {
  text-align: center;
  font-size: 25px;
}
.icono-clientes {
  margin: 0px 15px 0px 15px;
  width: 100%;
}

/*=======================  Menú  pantalla de escritorio ==================================*/

@media screen and (max-width: 4000px) {
  /* banner home */
  .containerHome {
    justify-content: space-evenly;
  }
  .imgLetrasLogo {
    width: 470px;
  }
  .imgHome {
    width: 460px;
    margin-top: 15px;
    display: block;
  }

  .titulo-home {
    font-size: 90px;
  }
  .boton-contactanos-home {
    width: 50%;
    font-size: 40px;
  }
  /* ******** desarrollamos software a la medida ********** */

  #titulo-software {
    font-size: 70px;
  }

  #titulo-necesidad {
    font-size: 55px;
  }
  .titulo-caracteristicas {
    font-size: 46px;
  }
  .parrafo-caracteristicas {
    font-size: 35px;
  }
  /* ******** Descubre beneficios ********** */
  .img-beneficios img {
    width: 330;
    height: 250px;

  }

  .contenedor-descubre-beneficios {
    display: flex;
    margin: 20px;
  }

  .titulo-info-beneficios {
    font-size: 55px;
    margin-top: 50px;
  }
  .subtitulo-info-beneficios {
    font-size: 40px;
  }
  .informacion-beneficios-caracteristicas {
    margin-bottom: 46px;
    margin-top: 0px;
  }
  #informacion {
    font-size: 37px;
  }
  /* lo mas importante son nuestros clientes */

  .subtitulo-info-clientes {
    text-align: center;
    font-size: 50px;
  }
  h5 {
    text-align: center;
    font-weight: bolder;
    font-size: 20px;
    margin: 25px 50px 100px;
  }
}

/* modificar css */

.descripcion-servicios article {
  text-align: justify;
  background-color: #ffffff;
  padding: 0% 10%;
}

.beneficios {
  width: 33%;
  padding: 20px;
  float: left;

}

.card-servicios {
  height: 160px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border: 10px solid #eee;
  border-radius: 20px;
  box-shadow: inset 5px 5px 10px #bbb, inset -5px -5px 10px #fff;
  transition: 0.5s;
}

.card-servicios:hover {
  height: 300px;
}

.card-servicios h2{
  background-color: #00000000;
}

.icon-servicios {
  width: 60px;
  height: 60px;
  color: rgb(0, 0, 0);
  font-size: 30px;
  background-color: rgba(9, 31, 78, 0.795);
  margin: 20px auto;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 8px 8px 10px #ddd, -8px -8px 10px #fff;
}

.content-servicios {
  color: rgb(48, 47, 47);
  background-color: #eee;
  padding: 10px;
  margin: 5px 20px;
  border-radius: 8px;
  box-shadow: 8px 8px 10px #ddd, -8px -8px 10px #fff;
  transform: translateY(-80px) scale(0);
  transition: all 0.5s;
}

.card-servicios:hover .content-servicios {
  transform: translateY(0) scale(1);
}

.content-servicios h3 {
  text-shadow: 2px 2px 0px #fff;
}

.content-servicios p {
  text-align: justify;
}

.servicios-lista {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container-s {
  float: left;
  width: 33%;
  height: 300px;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card-s {
  position: relative;
  height: 254px;
  background: white;
  padding: 16px;
  border: 1px solid #232323;
  transition: all 0.5s ease-in-out;
}

.card-hidden-s {
  display: flex;
  transform: translateY(200%);
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.5s ease-in, opacity 0.3s ease-in;
  opacity: 0;
}

.card-border-s {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  border-radius: 8px;
  z-index: -1;
}

/*Hovers*/
.card-s:hover {
  transform: translate(-15px, -15px);
  border-color: #5bc0eb;
}

.card-s:hover .card-hidden-s {
  transform: translateY(0);
  opacity: 1;
}

.card-s:hover .title-s {
  transform: translateY(200%);
  display: none;
}

/*Text*/
.title-s {
  position: absolute;
  bottom: 1rem;
  transition: transform 0.25s ease-out;
}

.card-hidden-s p {
  text-align: justify;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

/*Button*/
.button-s {
  padding: 8px;
  background-color: #5bc0eb;
  cursor: pointer;
}

.button-s:hover {
  background-color: #53aed4;
}

.button-servicios {
  display: flex;
  justify-content: center;
}

/* ******** DESKTOP de 12 pulgadas******* */
@media (max-width: 1900px) {
  /* banner home */
  .containerHome {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    display: flex;
    justify-content: space-evenly;
  }

  .imgLetrasLogo {
    width: 340px;
  }
  .imgHome {
    width: 360px;
    margin-top: 15px;
    display: block;
  }

  .titulo-home {
    font-size: 60px;
  }

  .container-home-letras {
    margin: 75px 0px 75px 0px;
    /*
        display: flex;
        flex-direction: column;
        justify-content: space-evenly
        */
  }
  .boton-contactanos-home {
    font-size: 20px;
  }

  /* ******** desarrollamos software a la medida ********** */

  #titulo-software {
    font-size: 40px;
  }

  #titulo-necesidad {
    font-size: 30px;
  }
  .titulo-caracteristicas {
    font-size: 20px;
  }
  .parrafo-caracteristicas {
    font-size: 18px;
  }

  /* ******** Descubre beneficios ********** */
  .contenedor-descubre-beneficios {
    display: flex;
    margin: 80px;
  }

  .img-beneficios {
    width: 100%;
  }
  img {
    width: 80%;
  }

  .informacion-beneficios {
    margin-top: 10px;
  }

  .titulo-info-beneficios {
    font-size: 35px;
    font-weight: bolder;
  }
  .subtitulo-info-beneficios {
    font-size: 25px;
    margin: 30px;
  }
  #informacion {
    font-size: 20px;
  }
  .informacion-beneficios-caracteristicas {
    margin-bottom: 65px;
  }
  .subtitulo-info-clientes {
    font-size: 25px;
  }
  h5 {
    text-align: center;
    font-weight: bolder;
    font-size: 20px;
    margin: 25px 50px 50px;
  }
}

/* ******** IPAD ******* */
@media (max-width: 900px) {
  .descripcion-servicios article {
    text-align: justify;
    padding: 20px;
    width: 100%;
  }

  .beneficios {
    width: 100%;
    padding: 20px;
    position: relative;
  }

  .servicios-lista {
    position: relative;
  }

  .container-s {
    display: flex;
    width: 100%;

    padding: 20px;
  }



  /* .torcidoHome{
        transform: skewY(-6deg);
    } */
  .imgLetrasLogo {
    width: 240px;
  }
  .imgHome {
    width: 230px;
    margin-top: 15px;
    display: block;
  }

  .titulo-home {
    font-size: 50px;
    color: #cfdbe8;
  }
  .boton-contactanos-home {
    display: block;
    width: 30%;
    font-size: 15px;
  }
  /* ******** Descubre beneficios ********** */
  .img-beneficios {
    display: none;
  }
  .contenedor-descubre-beneficios {
    display: flex;
    margin: 20px;
  }
  .icono-beneficios {
    display: flex;
    justify-content: center;
  }

  .contenedor-informacion-beneficios-caracteristicas {
    margin-left: 20px;
    padding: 0px 40px;
  }
}

/* ******** MOVIL ******* */
@media (max-width: 600px) {
  /* HEADER HOME */

  .container-home-letras {
    margin: 20px 0px 0px 50px;
  }
  /* .container-home{
        display: flex;
        flex-direction: column;
    } */
  .imgHome {
    display: none;
  }
  .boton-contactanos-home {
    display: block;
    width: 50%;
    font-size: 15px;
  }
  .imgLetrasLogo {
    margin: 0;
  }
  /* desarrollamos software a la medida */
  #titulo-software {
    font-size: 30px;
  }

  #titulo-necesidad {
    font-size: 25px;
  }
  .contenedor-caracteristicas {
    display: flex;
    flex-direction: column;
  }
  .caracteristicas {
    margin-bottom: 10px;
  }

  /* ******** Descubre beneficios ********** */
  .img-beneficios {
    display: none;
  }

  .contenedor-descubre-beneficios {
    display: flex;
    margin: 20px;
  }

  .titulo-info-beneficios {
    font-size: 25px;
  }
  .subtitulo-info-beneficios {
    font-size: 20px;
  }
  .informacion-beneficios-caracteristicas {
    margin-bottom: 25px;
    margin-top: 0px;
  }

  /* lo mas importante son nuestros clientes */
  .contenedor-info-clientes {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .subtitulo-info-clientes {
    font-size: 20px;
  }
  .icono-clientes {
    margin: 5px;
    width: 20%;
  }
  .info-clientes {
    margin: 10px;
  }
}
@media (max-width: 400px) {
  .icono-beneficios {
    margin-bottom: 70px;
  }
}
.producto-1 {
  width: 150%;
  position: relative;
  height: 200px;
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 400px;
  vertical-align: center;
}
.card {
  text-align: center;
}
