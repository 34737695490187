.comunidad {
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: 60px;
  background-color: var(--color-primary);
  background-color: rgb(255, 255, 255);
}
.title h1 {
  text-align: center;
  font-weight: bolder;
  margin-top: 10px;
  width: 100%;
  font-size: 30px;
}

div.card-title.h5 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

p.card-text {
  font-size: 16px;
}

small.text-muted {
  font-size: 14px;
  font-weight: bold;
}
.fecha p {
  font-size: 12px;
  text-align: right;
}

.etiquetas {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
}
.etiquetas span {
  background-color: rgba(94, 151, 243, 0.13);
}
.etiquetas span {
  margin: 0% 4%;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.boton-tutorial {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.blog {
  width: 100%;
}
.card {
  float: left;
}

div.miniatura {
  width: 33%;
  padding: 3%;
  float: left;
}

a.btn:hover {
  padding: 5px;
}
.btn {
  --color: var(--color-primary);
  --color2: rgb(10, 25, 30);
  background-color: transparent;
  border-radius: 6px;
  border: 0.3px solid var(--color);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: "Roboto", "Segoe UI", sans-serif;
  text-transform: uppercase;
  color: black;
  text-align: center;
}

.btn::after,
.btn::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: var(--color);
}

.btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.btn:hover {
  color: var(--color2);
}

.btn:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}

/* Resolucion en Dispositivos Moviles < 960px */
@media (max-width: 960px) {
  div.miniatura {
    width: 100%;
    padding: 0%;
    position: relative;
  }
}
