.nosotros {
  position: relative;
  width: 100%;
  margin-top: 60px;
  background-color: var(--color-primary);
  background-color: rgba(255, 255, 255, 0.363);
}
.box-nosotros {
  width: 100%;
  position: relative;
  height: 600px;
}
.left {
  width: 50%;
  height: 600px;
  float: left;
  display: flex;
  align-items: center;
  padding: 10%;
}

.right {
  width: 50%;
  height: 600px;
  float: right;
  text-align: center;
}

.right img {
  width: 600px;
  padding: 0px;
}

.left article {
  background-color: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.144);
  width: 100%;
  position: relative;
  padding: 60px;
  transform: translateY(-20px);
  font-weight: 900;
}

.left:hover article {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  color: #ffffff;
  box-shadow: #00000086 4px 4px 8px 8px;
  background: rgb(117, 19, 254);
  background: -moz-linear-gradient(
    90deg,
    rgba(117, 19, 254, 1) 0%,
    rgba(42, 125, 212, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(117, 19, 254, 1) 0%,
    rgba(42, 125, 212, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(117, 19, 254, 1) 0%,
    rgba(42, 125, 212, 1) 100%
  );
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.titulos {
  font-weight: bold;
  list-style: none;
  padding: 20px;
  display: flex; /*Convertimos al menú en flexbox*/
  justify-content: space-between; /*Con esto le indicamos que margine todos los items que se encuentra adentro hacia la derecha e izquierda*/
  align-items: center; /*con esto alineamos de manera vertical*/
  margin-top: 30px;
}
.tecnologias {
  width: 100%;
  position: relative;

}

.tecnologias.partners {
  width: 100%;
  position: relative;
}

.animacion {
  height: 100px;
  position: relative;
}

.card {
  transform: translateY(-20px);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.144);
  margin-top: 20px;
  display: flex;
}

.card:hover {
  box-shadow: #0000003b 4px 4px 8px 8px;
  transform: translateY(0);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

div.card-body p {
  color: #000000;
  font-size: 18px;
}

p.card-text {
  color: white;
  text-align: justify;
}

div.card-header {
  color: black;
  background-color: var(--color-primary);
  height: 5px;
}
h2 {
  text-align: center;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.349);
  padding: 2px;
  width: 100%;
  color: rgb(0, 0, 0);
  backdrop-filter: blur(5px);
}
div.card-info {
  width: 33%;
  padding: 3%;
  float: left;
}

/* =========================================== Responsive 960 =========================================== */

@media (max-width: 960px) {
  div.card {
    width: 100%;
    display: flex;
    padding: 10px;
  }

  .left {
    width: 100%;
    height: 100px;
    position: relative;
    padding: 0%;
    margin-top: 40px;
    padding: 10px;
  }
  .left article {
    background-color: #ffffff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.144);
    padding: 30px;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
  }
  .nosotros-img img {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .right {
    width: 100%;
    height: 300px;

    display: flex;
    align-items: center;

    padding: 10px;
  }

  div.card-info {
    width: 100%;
    padding: 0%;
    position: relative;
    padding: 10px;
  }
}
