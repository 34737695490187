/*===================Tipografía Corporativa Delfos 369==========================*/
@font-face {
  font-family: "Ravensara Sans";
  src: local("Ravensara Sans Light"), local("./Ravensara-Sans-Light"),
    url("RavensaraSansLight.woff2") format("woff2"),
    url("RavensaraSansLight.woff") format("woff"),
    url("RavensaraSansLight.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
:root {
  --colorTextos: #0d0d0d;
  --color-primary:rgb(255,195,0) ;
}

html{
  width: 100%;
  height: 100%;
  font-family: "Ravensara Sans";
}
body{
  background-color: rgb(255, 255, 255);
  font-family: "Ravensara Sans";
}


p,
h1,
article, span {
  font-family: "Ravensara Sans";
  color: rgb(0, 0, 0);
  font-display: swap;
  text-align: center;
  font-weight: 600;


  /*  font-weight: 600; letras en negritas  */
}
/*===================Página Not Found 404==========================*/
.not-found {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: calc(60% );
  color: var(--color-primary);
}


.not-found h2{
color: var(--color-primary);
font-size: 40px;
text-align: center;
padding: 10% ;
background-color: #000000;
display: flex;
justify-content: center;

}

.log{
  width: 100%;
  display: flex;
justify-content: center;
}

.log img{
width: 500px;

}



.whatsapp{
  position: fixed;
  bottom: 10px;
  justify-content: left;
  margin-left: calc(100% - 60px);
  z-index: 100;
}



a {
  color: #0d6efd;
  color: var(--bs-link-color);
   text-decoration: none; 
}

.social p{
  text-align: center;
  color: #000000;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}


h6{
  text-align: center;
}