
@mixin white-gradient {
background-color: rgba(255, 251, 13, 0);


}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


// Styling
.slider-tecnologia {
  
	background: rgba(0, 0, 0, 0);
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.432);
	height: 60px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
        margin-top: 5px;
	}
	
	.slide {
		height: 100px;
		width: 250px;
	}
}

/* Tamaño iconos iconify
 */
.iconify{
    font-size: 40px;

}




