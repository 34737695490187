.box-contacto-home {
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: 60px;
  background-color: rgb(255, 255, 255);
  /*  background-image: url("../assets/img/contacto.png"); */
  background-repeat: no-repeat;
  background-size: cover;

  padding: 20px;
}

.container-contacto {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-left: calc(25%);
  background-color: #ffffff1f;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  backdrop-filter: blur(2px);
}

.container-contacto:hover {
  background-color: #ffffff38;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

div.title.contacto h1 {
  background-color: rgba(0, 255, 255, 0);
}

.grupo label {
  width: 100%;
  text-align: center;
  color: rgb(0, 0, 0);
}

.grupo input {
  width: 100%;
}

.grupo textarea {
  width: 100%;
}

.boton-formulario {
  display: flex;
  justify-content: center;
}

button {
  width: 9em;
  height: 3em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 4px #000000;
}

.boton-formulario button{
z-index: 1;
display: block;
}

button::before {
  content: "";
  width: 0;
  height: 3em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    var(--color-primary) 0%,
    var(--color-primary) 100%
  );
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

button:hover::before {
  width: 9em;
}

.social-media.contacto {
  display: flex;
  justify-content: center;
  background-color: rgba(250, 235, 215, 0);
}

.box-contacto {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  z-index: 1;
}

.box-contacto span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.box-contacto span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.box-contacto span::before {
  top: -40px;
  left: 40px;
  width: 50px;
  height: 50px;
  opacity: 1;
}

.box-contacto span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}

.box-contacto span:after {
  bottom: -40px;
  right: 40px;
  width: 50px;
  height: 50px;
  opacity: 1;
}

.box-contacto .content-contacto {
  position: relative;
  width: 50%;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-contacto:hover .content-contacto:hover {
  box-shadow: #0000004f 4px 4px 8px 8px;
}

.box .content h2 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}

div.boton-formulario a {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px 30px;
  color: #000000;
  box-shadow: 2px 2px 2px#000000;
  font-weight: 300;
}

div.boton-formulario:hover a {
  color: #000000;
  box-shadow: 4px 4px 8px#00000059;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

@media (max-width: 960px) {
  .box-contacto {
    margin-top: 0px;
  }

  .container-contacto {
    width: 100%;
    margin-left: 0%;
    padding: 0px;
    height: 100%;
  }

  .box-contacto .content-contacto {
    position: relative;
    width: 100%;
  }

  div.boton-formulario {
    padding: 30px;
    display: block;
  }

  div.boton-formulario a {
    background-color: #000000;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 30px;
    color: #ffffff;
    box-shadow: 2px 2px 2px#000000;
    font-weight: 300;
  }

  div.boton-formulario:hover a {
    color: #ffffff;
    box-shadow: 4px 4px 8px#00000059;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
  }
}
